import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64d3d412"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-payment" }
const _hoisted_2 = { class: "white-box" }
const _hoisted_3 = {
  style: {"margin-top":"24px"},
  class: "payment__invoice"
}
const _hoisted_4 = { class: "white-box payment__invoice" }
const _hoisted_5 = {
  style: {"margin":"42px 0"},
  class: "order-payment__footer",
  type: "flex"
}
const _hoisted_6 = { class: "white-box" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "payment__summary-additional-fee-all" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "payment__summary-discount" }
const _hoisted_13 = { class: "white-box" }
const _hoisted_14 = {
  style: {"margin":"42px 0"},
  class: "order-payment__footer",
  type: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_tags = _resolveComponent("tags")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_i18n_n = _resolveComponent("i18n-n")!
  const _component_PlusCircleOutlined = _resolveComponent("PlusCircleOutlined")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasPermission(_ctx.actions.SHOW_ORDER_STEPS))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "order-payment__steper",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              xs: { span: 24 },
              lg: { span: 16 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_steps, { current: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_step, {
                      disabled: "",
                      title: _ctx.t('ORDER_PERSONAL.STEP_TITLE')
                    }, null, 8 /* PROPS */, ["title"]),
                    _createVNode(_component_a_step, {
                      disabled: "",
                      title: _ctx.t('ORDER_SYMPTOMS.STEP_TITLE')
                    }, null, 8 /* PROPS */, ["title"]),
                    (!_ctx.forNfz)
                      ? (_openBlock(), _createBlock(_component_a_step, {
                          key: 0,
                          title: _ctx.t('ORDER_PAYMENT.STEP_TITLE')
                        }, null, 8 /* PROPS */, ["title"]))
                      : (_openBlock(), _createBlock(_component_a_step, {
                          key: 1,
                          title: _ctx.t('ORDER_PAYMENT.STEP_TITLE_NFZ')
                        }, null, 8 /* PROPS */, ["title"]))
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    (!_ctx.forNfz)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_a_row, {
            style: {"margin-top":"32px"},
            type: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: { span: 19 },
                md: { span: 19 },
                sm: { span: 24 },
                xs: { span: 24 }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.t("ORDER_PAYMENT.HEADER")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString(_ctx.t(`ORDER_PAYMENT.${_ctx.translationType}.SUB_HEADER`)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_form, {
            style: {"margin-top":"24px"},
            ref: "paymentFormTemplate",
            autocomplete: "off",
            model: _ctx.data.model,
            rules: _ctx.data.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: [
            { xs: 0, sm: 0, md: 0, lg: 0 },
            { xs: 32, sm: 32, md: 0, lg: 0 },
          ] }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    lg: 15,
                    md: 13,
                    sm: 24,
                    xs: 24,
                    class: "payment__data"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_a_row, { class: "white-box__row payment__type" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              lg: 8,
                              md: 10,
                              sm: 5,
                              xs: 10,
                              class: "white-box__field-name"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.PAYMENT_TYPE")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_a_col, {
                              lg: { span: 15, offset: 1 },
                              md: { span: 13, offset: 1 },
                              sm: { span: 16, offset: 2 },
                              xs: { span: 12, offset: 2 },
                              class: "white-box__value"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "paymentType" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_tags, {
                                      mode: "single",
                                      "allow-empty": false,
                                      "empty-text": _ctx.t('NO_DATA'),
                                      value: _ctx.data.model.paymentType,
                                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.model.paymentType) = $event)),
                                      data: _ctx.paymentTypeOptions,
                                      onChange: _ctx.onPaymentTypeChange
                                    }, null, 8 /* PROPS */, ["empty-text", "value", "data", "onChange"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _createVNode(_component_a_row, {
                        style: {"margin-top":"24px"},
                        class: "payment__invoice-checkbox"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "invoice.enable" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_checkbox, {
                                    checked: _ctx.data.model.invoice.enabled,
                                    "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.model.invoice.enabled) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.WANT_INVOICE")), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }, 8 /* PROPS */, ["checked"])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _withDirectives(_createElementVNode("div", _hoisted_3, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.HEADER")), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_a_row, { class: "white-box__row payment__invoice-full-name" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 10,
                                sm: 5,
                                xs: 10,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.FULL_NAME")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 13, offset: 1 },
                                sm: { span: 16, offset: 2 },
                                xs: { span: 12, offset: 2 },
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_row, { gutter: [0, { lg: 16, md: 16, sm: 16, xs: 16 }] }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_col, {
                                        lg: { span: 11, offset: 0 },
                                        md: { span: 24, offset: 0 },
                                        sm: { span: 24, offset: 0 },
                                        xs: { span: 24, offset: 0 },
                                        class: "payment__invoice-first-name"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_form_item, { name: "invoice.firstName" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_input, {
                                                value: _ctx.data.model.invoice.firstName,
                                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.model.invoice.firstName) = $event)),
                                                placeholder: 
                              _ctx.t('ORDER_PAYMENT.INVOICE.FIRST_NAME_PLACEHOLDER')
                            
                                              }, null, 8 /* PROPS */, ["value", "placeholder"])
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_a_col, {
                                        lg: { span: 11, offset: 2 },
                                        md: { span: 24, offset: 0 },
                                        sm: { span: 24, offset: 0 },
                                        xs: { span: 24, offset: 0 },
                                        class: "payment__invoice-last-name"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_form_item, { name: "invoice.lastName" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_input, {
                                                value: _ctx.data.model.invoice.lastName,
                                                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.model.invoice.lastName) = $event)),
                                                placeholder: 
                              _ctx.t('ORDER_PAYMENT.INVOICE.LAST_NAME_PLACEHOLDER')
                            
                                              }, null, 8 /* PROPS */, ["value", "placeholder"])
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_a_row, { class: "white-box__row payment__invoice-company-name" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 10,
                                sm: 5,
                                xs: 10,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.COMPANY_NAME")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 13, offset: 1 },
                                sm: { span: 16, offset: 2 },
                                xs: { span: 12, offset: 2 },
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "invoice.companyName" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _ctx.data.model.invoice.companyName,
                                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.model.invoice.companyName) = $event)),
                                        placeholder: 
                          _ctx.t('ORDER_PAYMENT.INVOICE.COMPANY_NAME_PLACEHOLDER')
                        
                                      }, null, 8 /* PROPS */, ["value", "placeholder"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_a_row, { class: "white-box__row payment__invoice-vat-number" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 10,
                                sm: 5,
                                xs: 10,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.NIP")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 13, offset: 1 },
                                sm: { span: 16, offset: 2 },
                                xs: { span: 12, offset: 2 },
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "invoice.vatNumber" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _ctx.data.model.invoice.vatNumber,
                                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.model.invoice.vatNumber) = $event)),
                                        placeholder: 
                          _ctx.t('ORDER_PAYMENT.INVOICE.NIP_PLACEHOLDER')
                        
                                      }, null, 8 /* PROPS */, ["value", "placeholder"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_a_row, { class: "white-box__row payment__invoice-street" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 10,
                                sm: 5,
                                xs: 10,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.STREET")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 13, offset: 1 },
                                sm: { span: 16, offset: 2 },
                                xs: { span: 12, offset: 2 },
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_row, { gutter: [0, { lg: 16, md: 16, sm: 16, xs: 16 }] }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_col, {
                                        lg: { span: 12, offset: 0 },
                                        md: { span: 24, offset: 0 },
                                        sm: { span: 24, offset: 0 },
                                        xs: { span: 24, offset: 0 },
                                        class: "payment__invoice-street-name"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_form_item, { name: "invoice.streetName" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_input, {
                                                value: _ctx.data.model.invoice.streetName,
                                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.model.invoice.streetName) = $event)),
                                                placeholder: 
                              _ctx.t('ORDER_PAYMENT.INVOICE.STREET_NAME_PLACEHOLDER')
                            
                                              }, null, 8 /* PROPS */, ["value", "placeholder"])
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_a_col, {
                                        lg: { span: 5, offset: 1 },
                                        md: { span: 24, offset: 0 },
                                        sm: { span: 24, offset: 0 },
                                        xs: { span: 24, offset: 0 },
                                        class: "payment__invoice-street-number"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_form_item, { name: "invoice.streetNumber" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_input, {
                                                value: _ctx.data.model.invoice.streetNumber,
                                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.model.invoice.streetNumber) = $event)),
                                                placeholder: 
                              _ctx.t(
                                'ORDER_PAYMENT.INVOICE.STREET_NUMBER_PLACEHOLDER'
                              )
                            
                                              }, null, 8 /* PROPS */, ["value", "placeholder"])
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_a_col, {
                                        lg: { span: 5, offset: 1 },
                                        md: { span: 24, offset: 0 },
                                        sm: { span: 24, offset: 0 },
                                        xs: { span: 24, offset: 0 },
                                        class: "payment__invoice-flat-number"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_form_item, { name: "invoice.flatNumber" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_input, {
                                                value: _ctx.data.model.invoice.flatNumber,
                                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.model.invoice.flatNumber) = $event)),
                                                placeholder: 
                              _ctx.t('ORDER_PAYMENT.INVOICE.FLAT_NUMBER_PLACEHOLDER')
                            
                                              }, null, 8 /* PROPS */, ["value", "placeholder"])
                                            ]),
                                            _: 1 /* STABLE */
                                          })
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_a_row, { class: "white-box__row payment__invoice-post-code" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 10,
                                sm: 5,
                                xs: 10,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.POST_CODE")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 13, offset: 1 },
                                sm: { span: 16, offset: 2 },
                                xs: { span: 12, offset: 2 },
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "invoice.postCode" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _ctx.data.model.invoice.postCode,
                                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.data.model.invoice.postCode) = $event)),
                                        placeholder: 
                          _ctx.t('ORDER_PAYMENT.INVOICE.POST_CODE_PLACEHOLDER')
                        
                                      }, null, 8 /* PROPS */, ["value", "placeholder"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_a_row, { class: "white-box__row payment__invoice-city" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 10,
                                sm: 5,
                                xs: 10,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.INVOICE.CITY")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 15, offset: 1 },
                                md: { span: 13, offset: 1 },
                                sm: { span: 16, offset: 2 },
                                xs: { span: 12, offset: 2 },
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "invoice.city" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _ctx.data.model.invoice.city,
                                        "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data.model.invoice.city) = $event)),
                                        placeholder: 
                          _ctx.t('ORDER_PAYMENT.INVOICE.CITY_PLACEHOLDER')
                        
                                      }, null, 8 /* PROPS */, ["value", "placeholder"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ])
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.data.model.invoice.enabled]
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_a_button, {
                          loading: _ctx.loading,
                          onClick: _ctx.submit,
                          class: "order-payment__submit",
                          disabled: !_ctx.valid || _ctx.summary.loadingPrice || _ctx.loading,
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUBMIT")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["loading", "onClick", "disabled"]),
                        _createVNode(_component_router_link, { to: "/make-appointment" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { class: "order-payment__cancel" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.CANCEL")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_a_col, {
                    lg: { span: 8, offset: 1 },
                    md: { span: 10, offset: 1 },
                    sm: 24,
                    xs: 24,
                    class: "payment__summary"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_a_row, { class: "white-box__row payment__summary-header" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              lg: 24,
                              md: 24,
                              sm: 24,
                              xs: 24,
                              class: "white-box__field-name"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.HEADER")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_row, { class: "white-box__row payment__sale-price" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              lg: 16,
                              md: 16,
                              sm: 16,
                              xs: 16,
                              class: "white-box__field-name"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.SALE_PRICE")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_a_col, {
                              lg: 8,
                              md: 8,
                              sm: 8,
                              xs: 8,
                              class: "white-box__value"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.summary.loadingPrice)
                                  ? (_openBlock(), _createBlock(_component_LoadingOutlined, { key: 0 }))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                      _createVNode(_component_i18n_n, {
                                        value: _ctx.summary.salePrice.value,
                                        format: {
                        key: 'currency',
                        currency: _ctx.summary.salePrice.currency,
                      }
                                      }, null, 8 /* PROPS */, ["value", "format"])
                                    ]))
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_row, { class: "white-box__row payment__summary-additional-fees" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_a_col, {
                                lg: 16,
                                md: 16,
                                sm: 16,
                                xs: 16,
                                class: "white-box__field-name"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.ADDITIONAL_FEE")), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: 8,
                                md: 8,
                                sm: 8,
                                xs: 8,
                                class: "white-box__value"
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.summary.loadingPrice)
                                    ? (_openBlock(), _createBlock(_component_LoadingOutlined, { key: 0 }))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                        _createVNode(_component_i18n_n, {
                                          value: _ctx.sumOfAdditonalFees,
                                          format: {
                          key: 'currency',
                          currency: _ctx.summary.price.currency,
                        }
                                        }, null, 8 /* PROPS */, ["value", "format"])
                                      ]))
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.additionalFees, (additionalFee) => {
                              return _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: additionalFee.name,
                                class: "payment__summary-additional-fee"
                              }, [
                                _createVNode(_component_a_col, {
                                  lg: 16,
                                  md: 16,
                                  sm: 16,
                                  xs: 16,
                                  class: "white-box__field-name"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t(`ORDER.ADDITIONAL_FEE.${additionalFee.name}`)), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */),
                                _createVNode(_component_a_col, {
                                  lg: 8,
                                  md: 8,
                                  sm: 8,
                                  xs: 8,
                                  class: "white-box__value"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, [
                                      _createVNode(_component_i18n_n, {
                                        value: additionalFee.value,
                                        format: {
                          key: 'currency',
                          currency: _ctx.summary.price.currency,
                        }
                                      }, null, 8 /* PROPS */, ["value", "format"])
                                    ])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ], 512 /* NEED_PATCH */)), [
                                [_vShow, !_ctx.summary.loadingPrice]
                              ])
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_row, { class: "white-box__row payment__summary-discount-value" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              lg: 16,
                              md: 16,
                              sm: 16,
                              xs: 16,
                              class: "white-box__field-name"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.DISCOUNT")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_a_col, {
                              lg: 8,
                              md: 8,
                              sm: 8,
                              xs: 8,
                              class: "white-box__value"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.summary.loadingPrice)
                                  ? (_openBlock(), _createBlock(_component_LoadingOutlined, { key: 0 }))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                      _createVNode(_component_i18n_n, {
                                        value: _ctx.summary.discountValue.value,
                                        format: {
                        key: 'currency',
                        currency: _ctx.summary.price.currency,
                      }
                                      }, null, 8 /* PROPS */, ["value", "format"])
                                    ]))
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_row, { class: "white-box__row payment__summary-sale-price" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              lg: 16,
                              md: 16,
                              sm: 16,
                              xs: 16,
                              class: "white-box__field-name"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.PRICE")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }),
                            _createVNode(_component_a_col, {
                              lg: 8,
                              md: 8,
                              sm: 8,
                              xs: 8,
                              class: "white-box__value"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.summary.loadingPrice)
                                  ? (_openBlock(), _createBlock(_component_LoadingOutlined, { key: 0 }))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                      _createVNode(_component_i18n_n, {
                                        value: _ctx.summary.price.value,
                                        format: {
                        key: 'currency',
                        currency: _ctx.summary.price.currency,
                      }
                                      }, null, 8 /* PROPS */, ["value", "format"])
                                    ]))
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _withDirectives(_createVNode(_component_a_row, {
                          class: "payment__summary-discount-button",
                          type: "flex"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.summary.discount.model.showInput = true)),
                                  type: "link"
                                }, {
                                  icon: _withCtx(() => [
                                    _createVNode(_component_PlusCircleOutlined)
                                  ]),
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.ADD_DISCOUNT_CODE")), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }, 512 /* NEED_PATCH */), [
                          [_vShow, !_ctx.summary.discount.model.showInput]
                        ]),
                        _withDirectives(_createVNode(_component_a_form, {
                          class: "payment__summary-discount-input",
                          ref: "paymentDiscountForm",
                          model: _ctx.summary.discount.model,
                          rules: _ctx.summary.discount.rules
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_a_row, {
                                class: _normalizeClass(
                      'white-box__row payment__summary-discount-code' +
                      (_ctx.summary.errors.length > 0
                        ? ' payment__summary-discount-code--error'
                        : '')
                    )
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_col, {
                                    lg: 10,
                                    md: 10,
                                    sm: 6,
                                    xs: 11,
                                    class: "white-box__field-name"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUMMARY.DISCOUNT_CODE")), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  }),
                                  _createVNode(_component_a_col, {
                                    lg: 14,
                                    md: 14,
                                    sm: 18,
                                    xs: 13,
                                    class: "white-box__value"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_form_item, { name: "discountCode" }, {
                                        help: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.errors, (error, index) => {
                                            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(_ctx.t("ERROR." + error.code)), 1 /* TEXT */))
                                          }), 128 /* KEYED_FRAGMENT */))
                                        ]),
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_input, {
                                            value: _ctx.summary.discount.model.discountCode,
                                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.summary.discount.model.discountCode) = $event)),
                                            onKeyup: _withKeys(_ctx.onDiscountCodeKeyPressEnter, ["enter"]),
                                            onChange: _ctx.onDiscountCodeChange,
                                            placeholder: 
                            _ctx.t('ORDER_PAYMENT.SUMMARY.DISCOUNT_CODE_PLACEHOLDER')
                          
                                          }, null, 8 /* PROPS */, ["value", "onKeyup", "onChange", "placeholder"])
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["class"])
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["model", "rules"]), [
                          [_vShow, _ctx.summary.discount.model.showInput]
                        ])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model", "rules"])
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_a_row, {
            style: {"margin-top":"32px"},
            type: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: { span: 19 },
                md: { span: 19 },
                sm: { span: 24 },
                xs: { span: 24 }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.t("ORDER_PAYMENT.HEADER_NFZ")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_a_button, {
              loading: _ctx.loading,
              onClick: _ctx.submit,
              class: "order-payment__submit",
              disabled: !_ctx.valid || _ctx.summary.loadingPrice,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.SUBMIT_NFZ")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["loading", "onClick", "disabled"]),
            _createVNode(_component_router_link, { to: "/make-appointment" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, { class: "order-payment__cancel" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("ORDER_PAYMENT.CANCEL")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}