
import {
  defineComponent,
  inject,
  ref,
  reactive,
  Ref,
  onMounted,
  watch,
  computed,
} from "vue";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { map, each, concat, debounce } from "lodash";
import Tags from "@hd2/common/src/components/Tags.vue";
import { Tag } from "@hd2/common/types";
import { useStore } from "../store";
import { useI18n } from "vue-i18n";
import { isValidNip } from "@hd2/common/src/utils";
import {
  Invoice,
  RuleObjectExt,
  OrderExt,
  Price,
  Currency,
  PaymentType,
  CreateOrderDataPatient,
  CreateOrderData,
} from "../../types";
import { notification } from "ant-design-vue";
import { AxiosStatic } from "axios";
import { useRouter } from "vue-router";
import { actions } from "../utils/const";
import { usePermissions } from "../composable/usePermissions";
import { Form } from "ant-design-vue";

const useForm = Form.useForm;

interface DataModel {
  paymentType: Set<Tag["id"]>;
  invoice: Invoice & { enabled: boolean };
}

interface Data {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentForm: any;
  model: DataModel;
  rules: Record<string, Array<RuleObjectExt>>;
}

interface PriceValueExt {
  value: Price["price"];
  currency: Currency;
}

interface DiscountModel {
  discountCode: string;
}

interface AdditionalFee extends PriceValueExt {
  name: string;
}

interface Error {
  code: string;
}

interface Discount {
  processing: boolean;
  showInput: boolean;
  model: DiscountModel;
  rules: Record<string, Array<RuleObjectExt>>;
}

interface Summary {
  price: PriceValueExt;
  loadingPrice: boolean;
  discountValue: PriceValueExt;
  salePrice: PriceValueExt;
  errors: Array<Error>;
  discount: Discount;
  additionalFees: Array<AdditionalFee>;
}

export const OrderPaymentComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    Tags,
    LoadingOutlined,
    PlusCircleOutlined,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const { hasPermission } = usePermissions();

    const mockup = store.state.runtimeConfig.mockup;
    const appType = store.state.runtimeConfig.type;

    const valid: Ref<boolean> = ref(false);
    const loading: Ref<boolean> = ref(false);
    const paymentTypeOptions: Ref<Array<Tag>> = ref([
      {
        id: "INTERNAL_BY_LINK" as PaymentType,
        name: t("ORDER.PAYMENT_TYPES.INTERNAL_BY_LINK"),
        desc: "",
      },
    ]);
    const paymentFormTemplate = ref();

    switch (store.state.order.appointmentType) {
      case "HOUSE": {
        paymentTypeOptions.value.push({
          id: "CASH" as PaymentType,
          name: t("ORDER.PAYMENT_TYPES.CASH.TITLE"),
          desc: t("ORDER.PAYMENT_TYPES.CASH.DESC"),
        });
      }
    }

    const data: Data = reactive({
      paymentForm: null,
      model: {
        paymentType: new Set(["INTERNAL_BY_LINK"]),
        invoice: {
          enabled: false,
          firstName: store.state.order.patients[0].firstName,
          lastName: store.state.order.patients[0].lastName,
          companyName: "",
          vatNumber: "",
          streetName: store.state.order.address.streetName,
          streetNumber: store.state.order.address.streetNumber,
          flatNumber: store.state.order.address.flatNumber,
          postCode: store.state.order.address.postCode,
          city: store.state.order.address.city,
        },
      },
      rules: {
        paymentType: [
          {
            validator(rule, value: DataModel["paymentType"]) {
              return new Promise((resolve, reject) => {
                if (value.size === 0) {
                  reject(t("ORDER_PAYMENT.PAYMENT_TYPE_REQUIRED"));
                } else {
                  resolve();
                }
              });
            },
            trigger: "change",
          },
        ],
        "invoice.firstName": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.firstName) {
                    reject(t("ORDER_PAYMENT.INVOICE.FIRST_NAME_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.lastName": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.lastName) {
                    reject(t("ORDER_PAYMENT.INVOICE.LAST_NAME_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.companyName": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.companyName) {
                    reject(t("ORDER_PAYMENT.INVOICE.COMPANY_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.vatNumber": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.vatNumber) {
                    reject(t("ORDER_PAYMENT.INVOICE.NIP_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!isValidNip(data.model.invoice.vatNumber)) {
                    reject(t("ORDER_PAYMENT.INVOICE.NIP_INVALID"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.streetName": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.streetName) {
                    reject(t("ORDER_PAYMENT.INVOICE.STREET_NAME_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.streetNumber": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.streetNumber) {
                    reject(t("ORDER_PAYMENT.INVOICE.STREET_NUMBER_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.postCode": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.postCode) {
                    reject(t("ORDER_PAYMENT.INVOICE.POST_CODE_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
        "invoice.city": [
          {
            validator: () => {
              return new Promise((resolve, reject) => {
                if (data.model.invoice.enabled) {
                  if (!data.model.invoice.city) {
                    reject(t("ORDER_PAYMENT.INVOICE.CITY_REQUIRED"));
                  }
                }
                resolve();
              });
            },
            trigger: "change",
          },
        ],
      },
    });

    data.paymentForm = useForm(data.model, data.rules);

    const summary: Summary = reactive({
      price: { currency: "PLN", value: 0.0 },
      loadingPrice: true,
      discountValue: { currency: "PLN", value: 0.0 },
      salePrice: { currency: "PLN", value: 0.0 },
      errors: [],
      additionalFees: [],
      discount: {
        processing: false,
        showInput: false,
        model: {
          discountCode: "",
        },
        rules: {},
      },
    });

    const sumOfAdditonalFees = computed(() => {
      let sum = 0;
      each(summary.additionalFees, (additionalFees) => {
        sum += additionalFees.value;
      });
      return sum;
    });

    const refreshSlot = async (id: OrderExt["id"]) => {
      return await http.post("patient-portal/api/slot/extend", {
        id,
      });
    };

    const checkForm = () => {
      data.paymentForm
        .validate()
        .then(() => {
          valid.value = true;
        })
        .catch(() => {
          valid.value = false;
        });
    };

    const calcPrice = async (
      id: OrderExt["id"],
      discountCode: DiscountModel["discountCode"] = "",
      paymentType: DataModel["paymentType"]
    ) => {
      summary.loadingPrice = true;

      try {
        const calcRes = await http
          .post(`patient-portal/api/price/calculate`, {
            slotReservationId: Number(id),
            paymentType: Array.from(paymentType)[0],
            voucher: discountCode,
          })
          .then((res) => res.data);
        summary.salePrice = { value: calcRes.basePrice, currency: "PLN" };
        summary.discountValue = {
          value: calcRes.discount,
          currency: "PLN",
        };
        if (calcRes.cashPaymentCost) {
          summary.additionalFees = [
            {
              name: "PAYMENT_TYPE",
              value: calcRes.cashPaymentCost,
              currency: "PLN",
            },
          ];
        } else {
          summary.additionalFees = [];
        }

        summary.price = { value: calcRes.price, currency: "PLN" };

        switch (calcRes.voucherStatus) {
          case "EXPIRED": {
            summary.errors = [{ code: "2001" }];
            break;
          }
          case "REDEEMED": {
            summary.errors = [{ code: "2002" }];
            break;
          }
          case "RETIRED": {
            summary.errors = [{ code: "2003" }];
            break;
          }
          case "RETIRED_BY_PARTNER": {
            summary.errors = [{ code: "2004" }];
            break;
          }
          case "INVALID": {
            summary.errors = [{ code: "2005" }];
            break;
          }
          default: {
            summary.errors = [];
          }
        }

        summary.loadingPrice = false;
      } catch {
        notification.open({
          message: t("ERROR.4892"),
          class: "error",
        });
      } finally {
        if (discountCode !== null) {
          checkForm();
        }
      }
    };

    const submit = async () => {
      loading.value = true;
      if (store.state.order.setDefaultAddress) {
        await http.put(`patient-portal/api/patient-profile`, {
          address: {
            city: store.state.order.address.city,
            streetNumber: store.state.order.address.streetNumber,
            flatNumber: store.state.order.address.flatNumber,
            postCode: store.state.order.address.postCode,
            streetName: store.state.order.address.streetName,
          },
        });
      }

      const resBody: CreateOrderData = {
        paymentType: Array.from(data.model.paymentType)[0] as PaymentType,
        email: store.state.order.email,
        discountVoucher: summary.discount.model.discountCode,
        phoneNumber:
          store.state.order.phoneNumber.prefix +
          store.state.order.phoneNumber.number,
        slotReservationId: store.state.order.id,
        additionalPatients: map(store.state.order.patients, (patient) => {
          const symptoms = concat(
            map(patient.predefinedSymptoms, "name"),
            patient.otherSymptoms.replace(", ", ",").split(",")
          );

          const tmpPatient: CreateOrderDataPatient = {
            firstName: patient.firstName,
            lastName: patient.lastName,
            additionalPatientNotes: symptoms.join(", "),
          };

          if (patient.identificationDocument.type === "PESEL") {
            tmpPatient.pesel = patient.identificationDocument.number;
          } else {
            tmpPatient.identificationDocument = patient.identificationDocument;
            tmpPatient.birthday = patient.birthdate;
          }

          return tmpPatient;
        }),
        address: {
          city: store.state.order.address.city,
          streetNumber: store.state.order.address.streetNumber,
          flatNumber: store.state.order.address.flatNumber,
          postCode: store.state.order.address.postCode,
          streetName: store.state.order.address.streetName,
          additionalInfo: store.state.order.address.additionalInfo,
        },
      };
      if (data.model.invoice.enabled) {
        resBody.invoiceAddress = {
          firstName: data.model.invoice.firstName,
          lastName: data.model.invoice.lastName,
          vatNumber: data.model.invoice.vatNumber,
          city: data.model.invoice.city,
          postCode: data.model.invoice.postCode,
          streetName: data.model.invoice.streetName,
          streetNumber: data.model.invoice.streetNumber,
          flatNumber: data.model.invoice.flatNumber,
        };
      }
      try {
        const appointmentId = await http
          .post(`patient-portal/api/appointments`, resBody)
          .then((res) => res.data);
        if (
          resBody.paymentType === "INTERNAL_BY_LINK" &&
          summary.price.value > 0
        ) {
          const paymentData = await http
            .get(
              `/patient-portal/api/payment/redirect?appointmentId=${appointmentId}`
            )
            .then((res) => res.data);
          const form = document.createElement("form");

          form.setAttribute("method", mockup ? "get" : "post");
          form.setAttribute("action", paymentData.redirectUrl);

          for (const key in paymentData.redirectParams) {
            if (paymentData.redirectParams[key] != null) {
              const propInput = document.createElement("input");
              propInput.setAttribute("type", "hidden");
              propInput.setAttribute("name", key);
              propInput.setAttribute("value", paymentData.redirectParams[key]);
              form.appendChild(propInput);
            }
          }

          document.getElementsByTagName("body")[0].appendChild(form);
          form.submit();
        } else {
          router.push({
            name: "AppointmentSuccess",
            params: { id: appointmentId },
          });
        }
      } catch {
        notification.open({
          message: t("ERROR.4237"),
          class: "error",
        });
      } finally {
        loading.value = false;
      }
    };

    const onDiscountCodeChange = debounce(function (e: {
      target: HTMLInputElement;
    }) {
      if (summary.discount.processing !== true) {
        calcPrice(store.state.order.id, e.target.value, data.model.paymentType);
      } else {
        summary.discount.processing = false;
      }
    },
    1000);

    const onPaymentTypeChange = () => {
      calcPrice(
        store.state.order.id,
        summary.discount.model.discountCode,
        data.model.paymentType
      );
    };

    const onDiscountCodeKeyPressEnter = (e: { target: HTMLInputElement }) => {
      summary.discount.processing = true;
      calcPrice(store.state.order.id, e.target.value, data.model.paymentType);
    };

    watch(
      data.model,
      () => {
        checkForm();
      },
      { deep: true }
    );

    onMounted(async () => {
      try {
        await refreshSlot(props.id);
      } catch {
        store.commit("clearOrder");
        notification.open({
          message: t("ERROR.4865"),
          class: "error",
        });
        router.push({ name: "NfzVisitType" });
      }

      await calcPrice(
        store.state.order.id,
        summary.discount.model.discountCode,
        data.model.paymentType
      );
    });

    return {
      t,
      valid,
      loading,
      data,
      summary,
      calcPrice,
      hasPermission,
      actions,
      paymentTypeOptions,
      paymentFormTemplate,
      onPaymentTypeChange,
      onDiscountCodeKeyPressEnter,
      onDiscountCodeChange,
      submit,
      sumOfAdditonalFees,
      translationType: computed(() =>
        ["WELBI"].includes(appType) ? appType : "GENERAL"
      ),
      forNfz: computed(() => {
        return store.getters.isForNfz;
      }),
    };
  },
});
export default OrderPaymentComponent;
